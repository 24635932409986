import {
  useGetPrHeaderLazyQuery,
  useGetPoHeaderLazyQuery,
  useGetGeneralDoLazyQuery,
  useGetGrtnHeaderLazyQuery,
  PoType,
} from '../../generated/graphql';
import { useEffect } from 'react';

export const useGPQueries: any = ({ docType, CompanyID, transactionID }) => {
  //----------GP Queries----------//
  const [
    fetchPRHeader,
    {
      loading: PRHeaderLoading,
      called: PRHeaderCalled,
      data: { getPRHeader } = { getPRHeader: [] },
    },
  ] = useGetPrHeaderLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    fetchPOHeader,
    {
      loading: POHeaderLoading,
      called: POHeaderCalled,
      data: { getPOHeader } = { getPOHeader: [] },
    },
  ] = useGetPoHeaderLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    fetchGRTNHeader,
    {
      loading: GRTNHeaderLoading,
      called: GRTNHeaderCalled,
      data: { getGRTNHeader } = { getGRTNHeader: [] },
    },
  ] = useGetGrtnHeaderLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    fetchDOHeader,
    {
      loading: DOHeaderLoading,
      called: DOHeaderCalled,
      data: { getGeneralDO } = { getGeneralDO: [] },
    },
  ] = useGetGeneralDoLazyQuery({
    fetchPolicy: 'network-only',
  });

  let queryLoading, queryCalled, listData, subMenuName, primaryKey;

  switch (docType) {
    case 'purchase-requisition':
      queryLoading = PRHeaderLoading;
      queryCalled = PRHeaderCalled;
      listData = getPRHeader;
      subMenuName = 'Purchase Requisition';
      primaryKey = 'PRHeaderID';
      break;

    case 'generate-po-from-pr':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Generate PO from PR';
      primaryKey = 'POHeaderID';
      break;

    case 'centralised-po':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Centralised PO';
      primaryKey = 'POHeaderID';
      break;

    case 'direct-po':
      queryLoading = POHeaderLoading;
      queryCalled = POHeaderCalled;
      listData = getPOHeader;
      subMenuName = 'Direct PO';
      primaryKey = 'POHeaderID';
      break;

    case 'good-return-note':
      queryLoading = GRTNHeaderLoading;
      queryCalled = GRTNHeaderCalled;
      listData = getGRTNHeader;
      subMenuName = 'Goods Return Note';
      primaryKey = 'GRTNHeaderID';
      break;

    case 'delivery-order':
      queryLoading = DOHeaderLoading;
      queryCalled = DOHeaderCalled;
      listData = getGeneralDO;
      subMenuName = 'Goods Receive Note';
      primaryKey = 'DOHeaderID';
      break;
  }

  useEffect(() => {
    switch (docType) {
      case 'purchase-requisition':
        fetchPRHeader({
          variables: {
            PRHeaderID: transactionID,
            CompanyID: CompanyID,
          },
        });
        break;

      case 'generate-po-from-pr':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID: CompanyID,
            POType: PoType.PoFromPr,
          },
        });
        break;

      case 'centralised-po':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID: CompanyID,
            POType: PoType.CentralisedPo,
          },
        });
        break;

      case 'direct-po':
        fetchPOHeader({
          variables: {
            POHeaderID: transactionID,
            CompanyID: CompanyID,
            POType: PoType.DirectPo,
          },
        });
        break;

      // GRN and GRTN

      case 'good-return-note':
        fetchGRTNHeader({
          variables: {
            GRTNHeaderID: transactionID,
            CompanyID: CompanyID,
          },
        });
        break;

      case 'delivery-order':
        fetchDOHeader({
          variables: {
            DOHeaderID: transactionID,
            CompanyID: CompanyID,
          },
        });
        break;
    }
  }, [docType]);

  return {
    queryLoading,
    queryCalled,
    listData,
    subMenuName,
    primaryKey,
  };
};
