import HeaderGL from 'containers/GeneralLedgerModule/Hooks/PDFTemplate/HeaderGL'
import React from 'react'
import TablePR from './TablePR'
import TableAdvance from './APTemplate/TableAdvance'
import TableAPItem from './APTemplate/TableAPItem'
import TableAPRefund from './APTemplate/TableAPRefund'
import FooterPR from './FooterPR'

export const APARBodyTemplate = (props: any) => {
  const {
    fontStyle,
    title,
    previewDetail,
    accountType,
    docType,
    itemName,
    creditorOrDebtor,
    getUsersByAccountAndSoftware,
    remapData,
    page,
    pageLength,
    hasItem,
    currencyCode,
  } = props

  return (
    <>
      {page === 0 && (
        <>
          <HeaderGL />
          <div style={fontStyle}>
            <b>{title}</b>
          </div>
        </>
      )}

      {previewDetail && (
        <div style={{ border: '0.5px solid', margin: '10px' }}>
          <TablePR
            creditorOrDebtor={previewDetail?.[creditorOrDebtor]}
            data={previewDetail}
            name={
              previewDetail?.[creditorOrDebtor]?.CompanyName ??
              previewDetail?.[creditorOrDebtor]?.DebtorName
            }
            accountType={accountType}
            docType={docType}
            page={page}
            pageLength={pageLength}
          />

          {/* =====================================
          ============TABLE SECTION============
          ===================================== */}
          {docType === 'advance' ? (
            <>
              <TableAdvance
                data={previewDetail}
                docType={'advance'}
                currencyCode={currencyCode}
              />
            </>
          ) : docType === 'payment' ||
            docType === 'refund' ||
            docType === 'official-receipt' ? (
            !!remapData && (
              <>
                <TableAPRefund
                  data={remapData[page]}
                  allData={previewDetail}
                  allocation={remapData[page]?.Allocation}
                  page={page}
                  pageLength={pageLength}
                  currencyCode={currencyCode}
                />
              </>
            )
          ) : (
            !!remapData && (
              <TableAPItem
                item={remapData[page]?.[itemName]}
                allData={previewDetail?.[itemName]}
                page={page}
                pageLength={pageLength}
                currencyCode={currencyCode}
              />
            )
          )}
        </div>
      )}

      {/* Footer */}
      {!!previewDetail &&
      ((page === pageLength - 1 && !!hasItem) || hasItem === false) ? (
        <FooterPR
          data={previewDetail}
          docType={docType}
          getUser={getUsersByAccountAndSoftware}
        />
      ) : (
        <div className="page-break"></div>
      )}
    </>
  )
}

export default APARBodyTemplate
