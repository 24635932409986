import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAccountPeriodYearPeriodQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeQuery,
  useGetEntityCoaQuery,
  useGetJournalTypeQuery,
  useGetMasterCoaCodeQuery,
} from 'generated/graphql'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface GLJournalByCostCentreParamsProps {
  StartDate: Date
  EndDate: Date
  Year: Number
  Period: Number
  AccountCode: string[]
  JournalType: string[]
  JournalNo: string
  AccountName: string
  Description: string
  CostCentreCode: string[]
  ReferenceNo: string[]
}

export interface IMasterCOAProps {
  Name: string
  MasterCOAID: string
  ParentMasterCOAID: string
  IsControl: boolean
  IsLastNode: boolean
  AccountType: string
  Code: string
  Level: number
}

export interface ICostCentreProps {
  CostCentreID: string
  Name: string
  CompanyID: string
  ParentCostCentreID: string
  Level: number
  Code: string
  MasterCode: string
  IsLastNode: boolean
  IsDefault: boolean
}

export interface IJournalTypeProps {
  JournalType: string
  JournalTypeID: string
}

export const GLJournalByCostCentreParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({})
  const [masterCOAData, setMasterCOAData] = useState<IMasterCOAProps[]>([])
  const [costCentreData, setCostCentreData] = useState<ICostCentreProps[]>([])
  const [journalTypeData, setJournalTypeData] = useState<IJournalTypeProps[]>(
    []
  )

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<GLJournalByCostCentreParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: () => {
      const masterCOAList =
        getEntityCOA?.length > 0
          ? getEntityCOA?.map(coa => coa?.MasterCOA)
          : // ?.filter(
            //   coa => coa?.IsLastNode === true && coa?.IsControl === false
            // )
            getMasterCOA?.filter(
              coa => coa?.IsLastNode === true && coa?.IsControl === false
            )
      setMasterCOAData((masterCOAList as any) as IMasterCOAProps[])
    },
  })

  const {
    loading: JournalTypeLoading,
    error: JournalTypeError,
    data: { getJournalType } = { getJournalType: [] },
  } = useGetJournalTypeQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: () => {
      setJournalTypeData(getJournalType)
    },
  })

  const {
    loading: accountPeriodLoading,
    error: accountPeriodError,
    data: { getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodYearPeriodQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'FYear' },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreCodeQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      //   orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
    onCompleted: () => {
      setCostCentreData((getCostCentre as any) as ICostCentreProps[])
    },
  })

  const getObjectValues = (values: string[], dataType: string) => {
    switch (dataType) {
      case 'AccountCode':
        return masterCOAData.filter(x => {
          return values?.some(y => y === x.Code)
        })
      case 'JournalType':
        return getJournalType?.filter(x => {
          return values?.some(y => y === x?.JournalType)
        })
      case 'CostCentreCode':
        return getCostCentre?.filter(x => {
          return values?.some(y => y === x?.MasterCode)
        })
      default:
        return []
    }
  }

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/general-ledger/${CompanyID}/digital-report/gl-journal-costcentre-report/generated`,
      state: {
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        Year: data.Year,
        Period: data.Period,
        AccountCode:
          !!watch('AccountCode') && watch('AccountCode')?.length
            ? watch('AccountCode')?.join(',')
            : undefined,
        JournalType:
          !!watch('JournalType') && watch('JournalType')?.length
            ? watch('JournalType')?.join(',')
            : undefined,
        JournalNo: data.JournalNo,
        AccountName: data.AccountName,
        Description: data.Description ? data.Description : undefined,
        CostCentreCode:
          !!watch('CostCentreCode') && watch('CostCentreCode')?.length
            ? watch('CostCentreCode')?.join(',')
            : undefined,
        ReferenceNo: data.ReferenceNo,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {JournalTypeLoading && <Loading />}
      {accountPeriodLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'GL Journal By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.map(el => el?.FYear)
                      .filter((element, index) => {
                        return (
                          getAccountPeriod
                            ?.map(el => el?.FYear)
                            .indexOf(element) === index
                        )
                      })
                      .sort((a, b) => b - a) || []
                  }
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Year', newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Year?.message}
                          error={errors?.Year ? true : false}
                          label="Financial Year"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Financial Year"
            name="Year"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Year?.message}
            error={errors?.Year ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.filter(x => x?.FYear === Number(watch('Year')))
                      .sort((a, b) => b.FPeriod - a.FPeriod) || []
                  }
                  getOptionLabel={option => `${option?.FPeriod}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Period', newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.FPeriod}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Period?.message}
                          error={errors?.Period ? true : false}
                          label="Financial Period"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Financial Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Period?.message}
            error={errors?.Period ? true : false}
          />

          {!EntityCOALoading && !masterCOALoading && (
            <Controller
              name="AccountCode"
              label="Account Code"
              fullWidth
              margin="dense"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => {
                return (
                  <Autocomplete
                    multiple
                    value={
                      getObjectValues(
                        watch('AccountCode'),
                        'AccountCode'
                      ) as IMasterCOAProps[]
                    }
                    fullWidth
                    options={masterCOAData}
                    disableCloseOnSelect
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Code}`
                    }
                    PopperComponent={AccCodeDropdownFullWidth}
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.map(x => x?.Code))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Account Code"
                      />
                    )}
                    renderOption={(option, { selected }) => {
                      return (
                        <>
                          <Checkbox checked={selected} color="primary" />
                          <div>
                            <div>
                              <span className="xsTitle">{option.Name}</span>
                            </div>
                            <div className="desc">{option.Code}</div>
                          </div>
                        </>
                      )
                    }}
                  />
                )
              }}
            />
          )}

          <Controller
            name="JournalType"
            label="Journal Type"
            fullWidth
            margin="dense"
            ref={register}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Autocomplete
                  multiple
                  value={
                    getObjectValues(
                      watch('JournalType'),
                      'JournalType'
                    ) as IJournalTypeProps[]
                  }
                  fullWidth
                  options={getJournalType}
                  disableCloseOnSelect
                  getOptionLabel={option => `${option?.JournalType}`}
                  PopperComponent={AccCodeDropdownFullWidth}
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.map(x => x?.JournalType))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Journal Type"
                    />
                  )}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox checked={selected} color="primary" />
                        <div>
                          <div>
                            <span className="xsTitle">
                              {option.JournalType}
                            </span>
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
              )
            }}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="JournalNo"
            label="Journal No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.JournalNo?.message}
            error={errors?.JournalNo ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="AccountName"
            label="Account Name"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            // helperText={errors?.AccountName?.message}
            // error={errors?.AccountName ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            // helperText={errors?.Description?.message}
            // error={errors?.Description ? true : false}
          />

          <Controller
            name="CostCentreCode"
            label="Department"
            fullWidth
            margin="dense"
            ref={register}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Autocomplete
                  multiple
                  value={
                    getObjectValues(
                      watch('CostCentreCode'),
                      'CostCentreCode'
                    ) as ICostCentreProps[]
                  }
                  fullWidth
                  options={getCostCentre}
                  disableCloseOnSelect
                  getOptionLabel={option => `${option?.MasterCode}`}
                  PopperComponent={AccCodeDropdownFullWidth}
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.map(x => x?.MasterCode))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Department"
                    />
                  )}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox checked={selected} color="primary" />
                        <div>
                          <div>
                            <span className="xsTitle">{option.MasterCode}</span>
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
              )
            }}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="ReferenceNo"
            label="Reference No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            // helperText={errors?.ReferenceNo?.message}
            // error={errors?.ReferenceNo ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
