import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import React from 'react'

export const TableAPPayment = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { item, allData, page, pageLength, currencyCode } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 9
  }

  ///// Table header styles
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as const,
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingLeft: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
  }

  const thright = {
    width: '10%',
    textAlign: 'right' as const,
    backgroundColor: '#e7e6e6',
    borderBottom: '0.5px solid',
    paddingRight: '8px',
  }

  const thtaxamt = {
    width: '8%',
    borderBottom: '0.5px solid',
    textAlign: 'right' as const,
    backgroundColor: '#e7e6e6',
  }

  ///// Table data styles
  const tdright = {
    width: '10%',
    borderTop: '0.5px  solid',
    textAlign: 'right' as const,
    paddingRight: '8px',
  }

  const tdleftstretch = {
    borderTop: '0.5px  solid',
    textAlign: 'left' as const,
    paddingLeft: '8px',
  }

  const tdrightamt = {
    width: '10%',
    textAlign: 'right' as const,
    paddingRight: '8px',
  }

  const amount = allData?.reduce(
    (total, amountValue) => (total = total + amountValue.Amount),
    0
  )

  const taxAmount = allData?.reduce(
    (total, amountValue) => (total = total + amountValue.TaxAmt),
    0
  )

  const totalAmount = allData?.reduce(
    (total, amountValue) => (total = total + amountValue?.DocAmt),
    0
  )

  return (
    <>
      <div style={{ marginTop: '5px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleft}>Remark</th>
            <th style={thtaxamt}>Tax Rate</th>
            <th style={thright}>Base Amt</th>

            <th style={thright}>Tax Amt</th>

            <th style={thright}>Total Amt</th>
          </tr>
          {item?.map((v, i) => (
            <>
              <tr>
                <td style={{ textAlign: 'left', paddingLeft: '8px' }}>
                  {v?.Remark ?? 'NA'}
                </td>
                <td style={{ textAlign: 'right' }}>{`${v?.TaxRate}%`}</td>
                <td style={tdrightamt}>{amtStr(v?.Amount)}</td>
                <td style={tdrightamt}>{amtStr(v?.TaxAmt)}</td>
                <td style={tdrightamt}>{amtStr(v?.DocAmt)}</td>
              </tr>
            </>
          ))}
        </table>
      </div>

      {page === pageLength - 1 && (
        <div>
          <table
            style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
          >
            <tr>
              <td style={tdleftstretch}>{`Total Amount:`}</td>

              <td style={tdright}>{amtStr(amount)}</td>
              <td style={tdright}>{amtStr(taxAmount)}</td>
              <td style={tdright}>{amtStr(totalAmount)}</td>
            </tr>
          </table>
        </div>
      )}
    </>
  )
}

export default TableAPPayment
