import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TablePR = (props: any) => {
  const {
    data,
    creditorOrDebtor,
    docType,
    accountType,
    name,
    page,
    pageLength,
  } = props

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/* Left Column */}
          <span style={{ width: '60%', padding: '8px' }}>
            <table>
              <tr>
                <td>
                  <b>{name}</b>
                </td>
              </tr>
            </table>

            {creditorOrDebtor?.Address ? (
              <table style={{ borderCollapse: 'collapse' }}>
                {creditorOrDebtor?.Address?.country === 'Singapore' ? (
                  <>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.address}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.country}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.postCode}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.address}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        {creditorOrDebtor?.Address?.postCode}{' '}
                        {creditorOrDebtor?.Address?.state}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.country}</td>
                    </tr>
                  </>
                )}
              </table>
            ) : (
              <div style={{ marginTop: '10em' }}> </div>
            )}

            <table
              style={{
                textAlign: 'left',
                marginTop: '5px',
                borderCollapse: 'collapse',
              }}
            >
              <tr>
                <td>SST No. </td>
                <td>:</td>
                <td>{creditorOrDebtor?.GSTRegNo ?? ''}</td>
              </tr>

              {(creditorOrDebtor?.OfficeNo || creditorOrDebtor?.ContactNo) && (
                <tr>
                  <td style={{ paddingRight: '50px' }}>Tel No. </td>
                  <td>:</td>
                  <td>
                    {`+${creditorOrDebtor?.OfficeNo}` ??
                      `+${creditorOrDebtor?.ContactNo}` ??
                      ''}
                  </td>
                </tr>
              )}

              {data?.BankAccount && (
                <tr>
                  <td style={{ paddingRight: '16px' }}>Bank Info. </td>
                  <td>:</td>
                  <td>
                    {creditorOrDebtor?.BankName
                      ? `${creditorOrDebtor?.BankName} | ${creditorOrDebtor?.BankAccountNo}`
                      : ''}
                  </td>
                </tr>
              )}

              {accountType === 'account-payable' &&
                (docType === 'advance' ||
                  docType === 'credit-note' ||
                  docType === 'debit-note' ||
                  docType === 'payment') && (
                  <>
                    <tr>
                      <td style={{ paddingRight: '16px' }}>Attn. </td>
                      <td>:</td>
                      <td>
                        {creditorOrDebtor?.CreditorContact[0]?.Name ?? ''}
                      </td>
                      <tr></tr>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: '16px' }}>Tel No. </td>
                      <td>:</td>
                      <td>
                        {creditorOrDebtor?.CreditorContact[0]?.ContactNo ?? ''}
                      </td>
                      <tr></tr>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: '16px' }}>Email. </td>
                      <td>:</td>
                      <td>
                        {creditorOrDebtor?.CreditorContact[0]?.Email ?? ''}
                      </td>
                      <tr></tr>
                    </tr>
                  </>
                )}

              {accountType === 'account-receivable' && (
                <>
                  <tr>
                    <td style={{ paddingRight: '16px' }}>Attn. </td>
                    <td>:</td>
                    <td>
                      {creditorOrDebtor?.DebtorContact?.Name !== undefined
                        ? creditorOrDebtor?.DebtorContact?.Name
                        : ''}
                    </td>
                    <tr></tr>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '16px' }}>Tel No. </td>
                    <td>:</td>
                    <td>
                      {creditorOrDebtor?.DebtorContact?.ContactNo !== undefined
                        ? creditorOrDebtor?.DebtorContact?.ContactNo
                        : ''}
                    </td>
                    <tr></tr>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '16px' }}>Email. </td>
                    <td>:</td>
                    <td>
                      {creditorOrDebtor?.DebtorContact?.Email !== undefined
                        ? creditorOrDebtor?.DebtorContact?.Email
                        : ''}
                    </td>
                    <tr></tr>
                  </tr>
                </>
              )}

              {data?.Description &&
                docType !== 'advance' &&
                docType !== 'debit-note' &&
                docType !== 'credit-note' && (
                  <tr>
                    <td style={{ paddingRight: '16px' }}>Description </td>
                    <td>:</td>
                    <td>{data?.Description ?? ''}</td>
                    <tr></tr>
                  </tr>
                )}
            </table>
          </span>

          {/* Right Column */}
          <span style={{ width: '40%', padding: '8px' }}>
            <table>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>
                    <b>{data?.DocNo}</b>
                  </td>
                </tr>
                {data?.DocDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Document Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? '' : data?.RefNo}</td>
                </tr>

                {data?.PaymentMethod?.Name && (
                  <>
                    <tr>
                      <td>Payment Method </td>
                      <td>:</td>
                      <td>{data?.PaymentMethod?.Name}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {data?.PaymentMethod?.Name &&
                  data?.PaymentMethod?.Name === 'Cheque' && (
                    <>
                      <tr>
                        <td>Cheque No </td>
                        <td>:</td>
                        <td>{data?.ChequeNo ?? ''}</td>
                        <tr></tr>
                      </tr>
                    </>
                  )}

                {data?.BankAccount && (
                  <>
                    <tr>
                      <td>Bank Code </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.Code}</td>
                      <tr></tr>
                    </tr>
                    <tr>
                      <td>Bank A/C No. </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.AccountNo}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}

                <tr>
                  <td style={{ paddingRight: '20px' }}>Page </td>
                  <td>:</td>
                  <td>{`${page + 1} / ${pageLength ?? 1}`}</td>
                </tr>
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TablePR
